import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'

var lastUsed = 0;

const client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/sameepsi/quickswap06',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

const client02 = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/sameepsi/quickswap06',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const txClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://polygon.furadao.org/subgraphs/name/quickswap',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const accountsClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://polygon.furadao.org/subgraphs/name/quickswap',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const getClient = () => {
  if(lastUsed === 0) {
    lastUsed = 1;
    return client02;
  }
  else {
    lastUsed = 0;
    return client;
  }
}

export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://polygon.furadao.org/subgraphs/name/quickswap',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const v1Client = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/ianlapham/uniswap',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const stakingClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/way2rach/talisman',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const blockClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://polygon.furadao.org/subgraphs/name/quickswap',
  }),
  cache: new InMemoryCache(),
})
